import React from 'react';
import { FaEnvelopeOpen, FaMapMarker, FaMobileAlt } from 'react-icons/fa';
import PageHeader from '../components/PageHeader';
import MapSection from '../components/map/Map';

const location = {
  address: '1600 Amphitheatre Parkway, Mountain View, california.',
  lat: 37.42216,
  lng: -122.08427,
};

function Contact() {
  return (
    <>
      <div className="w-full p-12 md:p-6">
        <div className="shadow-lg p-12 md:p-6 sm:p-0">
          <PageHeader title="Contact Us" />

          <div className="flex flex-col px-32 py-12 md:px-12 sm:px-2 gap-8">
            <div className="flex flex-col gap-y-6 contact-frame">
              <iframe
                title="our location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3753.641343604854!2d7.482783393869173!3d9.107242680927106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0a7c3c06aaab%3A0x6f4e4393169dcdc3!2s3%20Elbe%20Cl%2C%20Maitama%2C%20Abuja%20904101%2C%20Federal%20Capital%20Territory!5e0!3m2!1sen!2sng!4v1703212398036!5m2!1sen!2sng"
                style={{ border: '0' }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                className="w-full h-[450px]"
              ></iframe>
            </div>
            <div className="flex gap-6">
              <div className="space-y-2 p-2 shadow-md rounded-md">
                <h2 className="text-2xl md:text-sm font-bold uppercase underline underline-offset-4 text-maincolor">
                  Location:
                </h2>
                <div className="flex sm:flex-col justify-between">
                  <div>
                    <FaMapMarker className="text-maincolor" />
                    <p className="flex sm:flex-col gap-1 items-center sm:items-start">
                      No 3b Elbe close, off panama street
                    </p>
                    <p>Maitama. Abuja.</p>
                  </div>
                </div>
              </div>
              <div className="space-y-2 p-2 shadow-md rounded-md">
                <h2 className="text-2xl md:text-sm font-bold uppercase underline underline-offset-4 text-maincolor">
                  Email:
                </h2>
                <FaEnvelopeOpen className="text-maincolor" />
                <p className="flex sm:flex-col gap-1 items-center sm:items-start">
                  info@nooncapitalandfinance.com
                </p>
              </div>
              <div className="space-y-2 p-2 shadow-md rounded-md">
                <h2 className="text-2xl md:text-sm font-bold uppercase underline underline-offset-4 text-maincolor">
                  Phone:
                </h2>
                <FaMobileAlt className="text-maincolor" />
                <p className="flex sm:flex-col gap-1 items-center sm:items-start">
                  +234 (805) 899 9969, +234 (803) 366 9557
                </p>
              </div>
            </div>
            {/* <div className="flex-1">
              <form
                ref={formRef}
                onSubmit={handleSubmit}
                name="google-sheet"
                className="space-y-3"
              >
                <div className="">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Your Name *"
                    className="w-full border-2 border-solid border-maincolor py-2 px-4 rounded-lg"
                  />
                </div>
                <div className="input-style">
                  <label htmlFor="name">Email</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email *"
                    className="w-full border-2 border-solid border-maincolor py-2 px-4 rounded-lg"
                  />
                </div>
                <div className="input-style">
                  <label htmlFor="name">Phone No</label>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Your Phone *"
                    className="w-full border-2 border-solid border-maincolor py-2 px-4 rounded-lg"
                  />
                </div>
                <div className="input-style">
                  <label htmlFor="name">Message</label>
                  <textarea
                    className="w-full border-2 border-solid border-maincolor py-2 px-4 rounded-lg"
                    placeholder="Your Message"
                  ></textarea>
                </div>
                <div className="input-style">
                  <input
                    type="submit"
                    value={loading ? "Loading..." : "SEND MESSAGE"}
                    className="bg-gradient-to-r from-maincolor to-black py-2 px-6 rounded-md text-purewhite hover:border hover:bg-gradient-to-l hover:bg-purewhite transition-all duration-300 ease-in-out"
                  />
                </div>
              </form>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
