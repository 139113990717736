import React from 'react';
import Hero from '../components/hero/Hero';
import About from '../components/home/About';
import PracticeAreas from '../components/home/PracticeAreas';
import CTA from '../components/CTA';
import Info from '../components/Info';

function Home() {
  return (
    <div>
      <>
        <Hero />
        <Info />
        <About />
        <PracticeAreas />
        <CTA />
      </>
    </div>
  );
}

export default Home;
