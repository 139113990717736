import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Products from './pages/Products';
import Team from './pages/Team';
import Privacy from './pages/Privacy';
import TermsCondition from './pages/Terms';

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <Outlet />
          </Layout>
        }
      >
        <Route index element={<Home />} />

        <Route path="about">
          <Route index element={<About />} />
        </Route>

        <Route path="products">
          <Route index element={<Products />} />
        </Route>

        {/* <Route path="team">
          <Route index element={<Team />} />
        </Route> */}

        <Route path="contact">
          <Route index element={<Contact />} />
        </Route>

        <Route path="privacy-policy">
          <Route index element={<Privacy />} />
        </Route>

        <Route path="terms-conditions">
          <Route index element={<TermsCondition />} />
        </Route>

        {/* Catch all page */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
}

export default App;
