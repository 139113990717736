import React from 'react';
import { motion } from 'framer-motion';
import AImage from '../images/success-growth-climbing-up.jpg';
import AboutImage from '../images/house-saving.jpg';
import PageHeader from '../components/PageHeader';
import Profit from '../images/profit-up.jpg';

const misionVision = [
  {
    id: 1,
    title: 'Mission Statement',
    content:
      'To empower businesses, individuals, and governments by providing innovative financial solutions, strategic guidance, and unparalleled service, fostering sustainable growth and prosperity.',
  },
  {
    id: 2,
    title: 'Vision',
    content:
      'To be a leading force in the financial industry recognized for our commitment to excellence, integrity, and the relentless pursuit of value creation for our clients and stakeholders.',
  },
];

function About() {
  return (
    <>
      <div className="p-12 md:p-6 sm:p-2">
        <div className="shadow-lg p-12 md:p-6">
          <PageHeader title="About Us" />
          <div className="flex flex-col px-32 sm:px-4">
            <div className="about-page flex-1 lg:order-1 flex items-center justify-center rounded-md w-full overflow-hidden">
              <img
                src={AImage}
                alt="abputImage"
                className="border-r-4 border-b-4 border-solid border-maincolor p-4 rounded-md"
              />
            </div>
            <div className="h-full flex-1 md:order-2 text-lg  p-8 sm:p-0 sm:py-4 rounded-tr-lg rounded-br-lg">
              <div className="text-md space-y-6 overflow-hidden">
                <motion.p
                  initial={{ opacity: 0, x: 200 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                  className="text-justify"
                >
                  Noon Capital and Finance is a dynamic and forward-thinking
                  financial institution. A Fund Management/Financial Planning
                  Firm committed to delivering comprehensive financial
                  solutions. We specialise in capital provision, investment
                  financing, and trade facilitation. The company also serves as
                  technical and financial advisors, managers, representatives,
                  and consultants on programs and contracts. We strive to add
                  value for our investors, customers, clients and collaborative
                  partners.
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, x: 200 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                  className="text-justify"
                >
                  We operate an investment scheme where investors contribute
                  money to get profit.
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, x: 200 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                  className="text-justify"
                >
                  <img
                    src={Profit}
                    alt="profitImage"
                    className="float-right sm:float-none m-3 border-4 border-maincolor p-4 h-[300px] other-images"
                  />
                  These investments are grounded in core non interest finance
                  principles which aim to meet our investor's financial needs
                  with honesty, fairness and integrity. At Noon Capital and
                  Finance, we ensure an equitable wealth distribution and profit
                  sharing formula.
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, x: 200 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                  className="text-justify"
                >
                  Our investment funds include equity funds, commodity funds,
                  real estate funds, money market funds and mixed funds.
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, x: 200 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                  className="text-justify"
                >
                  Established with a commitment to excellence, Noon Capital and
                  Finance is a non Interest Fund Management Firm that leverages
                  its expertise to serve as capital provider. Our investment
                  funds include equity s, investment financiers, lenders, and
                  trade facilitators.
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, x: 200 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                  className="text-justify"
                >
                  The company also offers its services as technical and
                  financial advisors, managers, representatives, and consultants
                  for the execution of projects, programs, and contracts.
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, x: 200 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                  className="text-justify"
                >
                  <img
                    src={AboutImage}
                    alt="aboutImage"
                    className="border-r-4 border-b-4 border-solid border-maincolor p-4 rounded-md float-left sm:float-none h-[300px] m-4 other-images"
                  />
                  Noon Capital and Finance caters to a diverse clientele,
                  including small and medium-sized enterprises (SMEs), large
                  corporations, government agencies, and individual investors.
                  The company's client-centric approach, coupled with a
                  commitment to integrity and innovation, positions it as a
                  preferred financial partner.
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, x: 200 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                  className="text-justify"
                >
                  As a responsible corporate entity, Noon Capital and Finance is
                  dedicated to sustainable business practices within the
                  agricultural sector. We recognize our role in contributing to
                  the broader economic landscape and aim to foster positive
                  change through ethical financial solutions in farming
                  communities. Our commitment to corporate social responsibility
                  aligns with our vision of being not just a financial
                  institution but a positive force for societal progress in
                  agriculture.
                </motion.p>
              </div>
              <motion.div
                initial={{ opacity: 0, x: 200 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { delay: 0.5, duration: 0.5 },
                }}
                viewport={{ once: true }}
                className=" mt-8"
              >
                <h2 className="text-3xl md:text-2xl font-bold uppercase underline underline-offset-4 text-maincolor">
                  Client Focus
                </h2>
                <p className="text-justify">
                  Noon Capital and Finance cater to a diverse clientele,
                  including small and medium-sized enterprises (SMEs), large
                  corporations, government agencies, and individual investors.
                  The companys client-centric approach and commitment to
                  integrity and innovation position it as a preferred financial
                  partner.
                </p>
              </motion.div>
            </div>
          </div>
          <div className="py-12 px-32 md:px-16 sm:px-4">
            <hr className="border-maincolor border-2 border-solid" />
          </div>
          <div className="px-32 xl:px-24 lg:px-16 md:px-12 sm:px-2">
            <div className="grid w-full grid-cols-8 gap-16 md:gap-8 p-6">
              {misionVision.map((items) => (
                <motion.div
                  key={items.id}
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                  className="relative col-span-4 md:col-span-8 bg-purewhite text-maincolor  p-4 rounded-md shadow-2xl"
                >
                  <h2 className="text-3xl font-bold mb-2 text-center">
                    {items.title}
                  </h2>
                  <div className="flex sm:flex-col gap-3 sm:gap-6 sm:items-center">
                    <p className="text-md font-light text-black">
                      {items.content}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
