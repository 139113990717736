import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaMobileAlt,
  FaEnvelopeOpen,
  FaLinkedin,
  FaArrowUp,
  FaMapMarker,
} from 'react-icons/fa';
import Logo from '../../images/noon-logo.png';

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <footer className=" bg-black text-whitesmokecolor p-16 md:p-12">
      <div className="flex justify-end">
        <FaArrowUp
          className="text-3xl text-maincolor cursor-pointer mb-4"
          onClick={scrollToTop}
        />
      </div>
      <div>
        <Link to={'/'} className="flex items-center mb-6">
          <img src={Logo} alt="Logo" className="w-[50px]" />{' '}
          <h1 className="text-4xl text-purewhite font-bold">
            Noon Capital and Finance
          </h1>
        </Link>
      </div>
      <div className="flex md:flex-col gap-6">
        <div className="flex-1 flex-col items-center justify-center">
          <div className="space-y-2">
            <h2 className="text-2xl md:text-sm font-bold uppercase underline underline-offset-4 text-maincolor">
              Location:
            </h2>
            <div>
              <div>
                <h2 className="font-bold uppercase">Abuja</h2>
                <p className="flex gap-1 items-center">
                  <FaMapMarker className="text-maincolor" /> No 3b Elbe close,
                  off panama street
                </p>
                <p>Maitama, Abuja.</p>
              </div>
            </div>
          </div>
          <div className="space-y-2">
            <h2 className="text-2xl md:text-sm font-bold uppercase underline underline-offset-4 text-maincolor">
              Email
            </h2>
            <p className="flex gap-1 items-center">
              <FaEnvelopeOpen className="text-maincolor" />
              info@nooncapitalandfinance.com
            </p>
          </div>
          <div className="space-y-2">
            <h2 className="text-2xl md:text-sm font-bold uppercase underline underline-offset-4 text-maincolor">
              Phone
            </h2>
            <p className="flex gap-1 items-center">
              <FaMobileAlt className="text-maincolor" /> +234 (805) 899 9969,
              +234 (803) 366 9557
            </p>
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-y-6 md:text-center md:items-center">
          <h2 className="text-2xl md:text-sm font-bold uppercase underline underline-offset-4 text-maincolor">
            Privacy
          </h2>

          <div className="flex flex-col space-y-4">
            <Link to={'/terms-conditions'}>Terms and Condition</Link>
            <Link to={'/privacy-policy'}>Privacy Policy</Link>
          </div>
          <h2 className="text-2xl md:text-sm font-bold uppercase underline underline-offset-4 text-maincolor">
            Connect with us:
          </h2>

          <div>
            <div className="flex items-center justify-right gap-6 text-2xl">
              <Link to={'https://twitter.com'}>
                <FaTwitter className="hover:text-maincolor relative hover:bottom-1 transition-all duration-300 ease-in-out" />
              </Link>
              <Link to={'https://facebook.com'}>
                <FaFacebook className="hover:text-maincolor relative hover:bottom-1 transition-all duration-300 ease-in-out" />
              </Link>
              <Link to={'https://instagram.com'}>
                <FaInstagram className="hover:text-maincolor relative hover:bottom-1 transition-all duration-300 ease-in-out" />
              </Link>
              <Link to={'https://linkedin.com'}>
                <FaLinkedin className="hover:text-maincolor relative hover:bottom-1 transition-all duration-300 ease-in-out" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center text-sm text-lightgray mt-12 lg:text-center md:text-xs">
        &copy; {new Date().getFullYear()} Noon Capital and Finance | All Right
        Reserved |{' '}
        <Link to={'https://www.miyradigitals.com.ng'} className="underline">
          Developer
        </Link>
      </div>
    </footer>
  );
}

export default Footer;
