import React from 'react';
import { motion } from 'framer-motion';
import HomeAboutImage from '../../images/aboutImage.jpg';
import { Link } from 'react-router-dom';

function About() {
  return (
    <div className="about-us flex lg:flex-col gap-8 min-h-screen p-32 lg:p-24 md:p-16 sm:p-12 items-center justify-center bg-gradient-to-r from-black to-maincolor text-purewhite">
      <div className="about-home flex-1 relative h-max  rounded-2xl overflow-hidden">
        <div className="absolute top-0 -right-3 -z-10 w-[102%] h-[103%] rounded-2xl bg-black" />
        <img
          src={HomeAboutImage}
          alt="aboutImage"
          className="rounded-2xl w-full h-auto hover:scale-150 transition-all duration-300 ease-in-out"
        />
      </div>
      <div className="h-full flex-1 text-[20px] sm:text-[18px] space-y-6 p-4 text-md overflow-hidden">
        <motion.p
          initial={{ opacity: 0, x: 200 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.5, duration: 0.5 },
          }}
          viewport={{ once: true }}
        >
          We operate an investment scheme where investors contribute money to
          get profit.
          {/* </motion.p>
        <motion.p
          initial={{ opacity: 0, x: 200 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.5, duration: 0.5 },
          }}
          viewport={{ once: true }}
          className="text-justify"
        > */}
          These investments are grounded in core non interest finance principles
          which aim to meet our investor's financial needs with honesty,
          fairness and integrity. At Noon Capital and Finance, we ensure an
          equitable wealth distribution and profit sharing formula.
          {/* </motion.p>
        <motion.p
          initial={{ opacity: 0, x: 200 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.5, duration: 0.5 },
          }}
          viewport={{ once: true }}
          className="text-justify"
        > */}
          Our investment funds include equity funds, commodity funds, real
          estate funds, money market funds and mixed funds.
        </motion.p>
      </div>
    </div>
  );
}

export default About;
