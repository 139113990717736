import React from 'react';
import Nav from './nav/Nav';
import Footer from './footer/Footer';

function Layout({ children }) {
  return (
    <div>
      <Nav />
      <main>
        <div className="pt-[150px]">{children}</div>
      </main>
      <Footer />
    </div>
  );
}

export default Layout;
