import React from 'react';
import PageHeader from '../components/PageHeader';
import { motion } from 'framer-motion';

function TermsCondition() {
  return (
    <div className="p-12 md:p-6 sm:p-2">
      <div className="shadow-lg p-12 md:p-6">
        <PageHeader title="Terms and Conditions" />
        <div className="flex flex-col px-32 sm:px-4">
          <div className="mt-8 space-y-4">
            <p className="my-4">Last Updated: 1st January, 2024</p>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl md:text-2xl font-bold uppercase underline underline-offset-4 text-maincolor">
                1. Acceptance of Terms
              </h2>
              <p>
                By accessing or using Noon Capital and Finance's website,
                services, or any other offerings, you agree to comply with and
                be bound by these Terms and Conditions.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl md:text-2xl font-bold uppercase underline underline-offset-4 text-maincolor">
                2. Use of Services
              </h2>
              <p>
                You agree to use our services for lawful purposes only. You are
                responsible for maintaining the confidentiality of your account
                information and for all activities that occur under your
                account.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl md:text-2xl font-bold uppercase underline underline-offset-4 text-maincolor">
                3. Intellectual Property
              </h2>
              <p>
                All content on our website, including text, graphics, logos, and
                images, is the property of Noon Capital and Finance and is
                protected by intellectual property laws. You may not use,
                reproduce, or distribute our content without our express written
                consent.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl md:text-2xl font-bold uppercase underline underline-offset-4 text-maincolor">
                4. Limitation of Liability
              </h2>
              <p>
                Noon Capital and Finance is not liable for any direct, indirect,
                incidental, consequential, or punitive damages arising out of
                your use of our services or the information provided on our
                website.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl md:text-2xl font-bold uppercase underline underline-offset-4 text-maincolor">
                5. Governing Law
              </h2>
              <p>
                These Terms and Conditions are governed by and construed in
                accordance with the laws of the Federal Republic of Nigeria. Any
                disputes arising out of these terms will be resolved in the
                courts of the Federal Republic of Nigeria.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl md:text-2xl font-bold uppercase underline underline-offset-4 text-maincolor">
                6. Changes to Terms and Conditions
              </h2>
              <p>
                Noon Capital and Finance reserves the right to update or modify
                these Terms and Conditions at any time without prior notice.
                Continued use of our services after any changes constitutes
                acceptance of the modified terms.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl md:text-2xl font-bold uppercase underline underline-offset-4 text-maincolor">
                7. Contact Information
              </h2>
              <p>
                For inquiries regarding these Terms and Conditions, please
                contact us at info@nooncapitalandfinance.com
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl md:text-2xl font-bold uppercase underline underline-offset-4 text-maincolor">
                By using Noon Capital and Finance's services, you acknowledge
                that you have read, understood, and agreed to these Terms and
                Conditions and the accompanying Privacy Policy.
              </h2>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsCondition;
