import React, { useState } from 'react';
import { motion } from 'framer-motion';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import HeroOne from '../../images/hero-one.jpg';
import HeroTwo from '../../images/hero-two.jpg';
import HeroThree from '../../images/hero-three.jpg';
import HeroFour from '../../images/hero-four.jpg';
import HeroFive from '../../images/hero-five.jpg';
import AImage from '../../images/success-growth-climbing-up.jpg';
import Hero0 from '../../images/house-saving.jpg';
import HeroSix from '../../images/profit-up.jpg';

const clearing = [
  {
    original: AImage,
    originalTitle: 'Hello',
  },
  {
    original: Hero0,
    originalTitle: 'Hello',
  },
  {
    original: HeroSix,
    originalTitle: 'Hello',
  },
  {
    original: HeroOne,
    originalTitle: 'Hello',
  },
  {
    original: HeroTwo,
    originalTitle: 'hi',
  },
  {
    original: HeroThree,
    originalTitle: 'Hell Yo',
  },
  {
    original: HeroFour,
    originalTitle: 'Anha',
  },
  {
    original: HeroFive,
    originalTitle: 'puck',
  },
];

const quote = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      delay: 2.5,
    },
  },
};

const singleWord = {
  initial: {
    opacity: 0,
    y: 50,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 2.5,
      duration: 1,
    },
  },
};

const smallText = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0, transition: { delay: 2.8, duration: 1 } },
};

const buttonAni = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0, transition: { delay: 3 } },
};

function Hero() {
  const [showContent, setShowContent] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const heroText = 'Noon Capital and Finance';

  return (
    <div>
      <motion.div
        initial={{
          height: '100%',
        }}
        animate={{
          height: '0%',
          transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 2.9 },
        }}
        onAnimationComplete={() => setShowImage(true)}
        className="absolute bg-maincolor h-screen w-full "
      ></motion.div>
      <div className="px-32 py-8 xl:px-24 lg:px-16 md:px-12 sm:px-4 min-h-screen">
        <div className="flex md:flex-col items-center justify-center sm:text-center">
          <div className="flex-1 md:order-2 space-y-6">
            <motion.h1
              className="text-6xl lg:text-5xl md:text-4xl md:mt-6 sm:mt-2 capitalize font-bold text-gray-800"
              variants={quote}
              initial="initial"
              animate="animate"
            >
              {heroText.split(' ').map((word, index) => (
                <motion.span
                  key={word + '-' + index}
                  className="inline-block"
                  variants={singleWord}
                  initial="initial"
                  animate="animate"
                >
                  {word}&nbsp;
                </motion.span>
              ))}
            </motion.h1>
            <motion.p
              variants={smallText}
              initial="initial"
              animate="animate"
              className="text-2xl font-bold md:text-md text-gray-600 capitalize flex items-center"
            >
              {' '}
              <TypeAnimation
                sequence={[
                  'Transforming Dreams into Financial Reality – Welcome to Noon Capital and Finance.',
                  1000, // wait time 1s
                  'Empowering Businesses, Enriching Lives – Experience Excellence with Noon Capital.',
                  1000,
                  'Innovative Solutions, Lasting Success – Your Journey Starts Here at Noon Capital and Finance.',
                  1000,
                  'Where Visionaries Thrive and Prosper – Explore the Future of Finance with Noon.',
                  1000,
                  'Harvesting Success, Cultivating Prosperity – Noon Capital, Your Partner in Growth.',
                  1000,
                ]}
                wrapper="span"
                speed={50}
                style={{
                  fontSize: '.9em',
                  fontWeight: 'bold',
                  display: 'inline-block',
                  height: '90px',
                  width: '400px',
                }}
                repeat={Infinity}
              />
            </motion.p>
            <motion.div
              variants={buttonAni}
              initial="initial"
              animate="animate"
              className="shadow-md p-4 border border-gray rounded-md"
            >
              <p className="card-text text-darkgray text-lg italic sm:text-2xl font-light">
                "We bridge the divide by providing adequate funding to
                profitable business opportunities"
              </p>
              <h5 className="card-title">
                ~{' '}
                <div className="flex flex-col">
                  <span className="font-bold text-lg">Nasir Ka'oje</span>
                  <span className="italic text-maincolor">Chairman</span>
                  <span className="font-bold">Noon Capital and Finance</span>
                </div>
              </h5>
            </motion.div>
          </div>
          <div className=" flex-1 flex items-center justify-center m-3 sm-m-0 border-4 border-maincolor rounded-md">
            {showImage && (
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 2.3, duration: 0.5 }}
                className="relative p-4 rounded-2xl hero-slide"
              >
                <ImageGallery
                  items={clearing}
                  showNav={false}
                  showPlayButton={false}
                  autoPlay={true}
                  slideInterval={5000}
                  showFullscreenButton={false}
                  height={400}
                />
              </motion.div>
            )}
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default Hero;
