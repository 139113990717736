import React from 'react';
import { motion } from 'framer-motion';
import PageHeader from '../components/PageHeader';
import CapitalProvision from '../images/aboutImage.jpg';
import Investment from '../images/hero-one.jpg';
import Lending from '../images/lending.jpeg';
import Trade from '../images/trade-facilitation.jpg';
import FinancialAdvisor from '../images/financial-advisor.webp';
import Management from '../images/management.png';
import Consultancy from '../images/consulting.jpeg';

const products = [
  {
    id: 1,
    title: 'Capital Provision',
    image: CapitalProvision,
    content:
      'Noon Capital and Finance is dedicated to providing capital to businesses across various sectors. Through innovative financial products and solutions, the company aims to empower businesses to meet their capital requirements and fuel growth.',
  },
  {
    id: 2,
    title: 'Investment Financing',
    image: Investment,
    content:
      'As investment financiers, Noon Capital and Finance actively engage in identifying and funding lucrative investment opportunities. The company collaborates with clients to structure financing arrangements that align with their objectives and contribute to the success of their ventures.',
  },
  {
    id: 3,
    title: 'Trade Facilitation',
    image: Trade,
    content:
      'Recognizing the importance of international trade, Noon Capital and Finance facilitates trade by providing financial instruments, trade finance solutions, and advisory services. The company plays a pivotal role in enabling smooth and efficient cross-border transactions.',
  },
  {
    id: 4,
    title: 'Technical and Financial Advisory',
    image: FinancialAdvisor,
    content:
      'Noon Capital and Finance serves as a trusted advisor, offering both technical and financial expertise to individuals, businesses, and government entities. The company provides insights, guidance, and strategic recommendations to ensure the successful planning and execution of projects and programs.',
  },
  {
    id: 5,
    title: 'Management and Representation',
    image: Management,
    content:
      'The company takes on roles as managers and representatives in various capacities, working closely with clients to oversee project execution, manage financial aspects, and represent their interests. This hands-on approach ensures the efficient implementation of initiatives.',
  },
  {
    id: 6,
    title: 'Consultancy Services',
    image: Consultancy,
    content:
      'Noon Capital and Finance offers consultancy services to clients seeking specialized finance, project management, and business strategy expertise. The companys team of seasoned professionals provides valuable insights and recommendations to optimize decision-making processes.',
  },
];

function Products() {
  return (
    <>
      <div className="w-full p-12 md:p-6">
        <div className="shadow-lg p-12 md:p-6 sm:p-0">
          <PageHeader title="Our Products & Services" />
          <div className="grid w-full grid-cols-8 gap-16 md:gap-8 p-6">
            {products.map((items) => (
              <motion.div
                key={items.id}
                initial={{ opacity: 0, y: 100 }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: { delay: 0.5, duration: 0.5 },
                }}
                viewport={{ once: true }}
                className="relative col-span-4 lg:col-span-8 bg-purewhite text-maincolor  p-4 rounded-md shadow-2xl"
              >
                <h2 className="text-3xl font-bold mb-2 text-center">
                  {items.title}
                </h2>
                <div>
                  <img
                    src={items.image}
                    alt="productImage"
                    className="w-full h-[300px] my-3 sm:h-[150px] product-image"
                  />
                </div>
                <div className="flex sm:flex-col gap-3 sm:gap-6 sm:items-center">
                  <p className="text-md sm:text-sm font-light text-justify text-black">
                    {items.content}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Products;
