import React from 'react';
import { motion } from 'framer-motion';
import InfoImage from '../images/house-saving.jpg';

function Info() {
  return (
    <div className="about-us flex lg:flex-col gap-8 min-h-screen p-32 lg:p-24 md:p-16 sm:p-12 items-center justify-center bg-darkgray text-purewhite">
      <div className="about-home flex-1 order-2 relative h-max  rounded-2xl overflow-hidden">
        <div className="absolute top-0 -right-3 -z-10 w-[102%] h-[103%] rounded-2xl bg-black" />
        <img
          src={InfoImage}
          alt="aboutImage"
          className="rounded-2xl w-full h-auto hover:scale-150 transition-all duration-300 ease-in-out"
        />
      </div>
      <div className="h-full flex-1 order-1 space-y-6 p-4 text-[20px] sm:text-[18px] overflow-hidden">
        <motion.p
          initial={{ opacity: 0, x: -200 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.5, duration: 0.5 },
          }}
          viewport={{ once: true }}
        >
          Noon Capital and Finance is a dynamic and forward-thinking financial
          institution. A Fund Management/Financial Planning Firm committed to
          delivering comprehensive financial solutions. We specialise in capital
          provision, investment financing, and trade facilitation. The company
          also serves as technical and financial advisors, managers,
          representatives, and consultants on programs and contracts. We strive
          to add value for our investors, customers, clients and collaborative
          partners.
        </motion.p>
      </div>
    </div>
  );
}

export default Info;
