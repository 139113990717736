import React from 'react';
import { FaEnvelope, FaPhone } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function CTA() {
  return (
    <div>
      <div className="text-center py-12 sm:py-4">
        <h1 className="text-5xl sm:text-3xl font-bold top-12">
          Have any Question
        </h1>
      </div>
      <div className="flex sm:flex-col border-2 border-gray">
        <div className="w-1/2 sm:w-full py-4 px-32 xl:px-24 lg:px-16 md:px12 sm:px-8 bg-purewhite text-maincolor min-h-[60vh] flex flex-col items-center justify-center why-us">
          <FaEnvelope className="text-6xl mb-3" />
          <Link
            to="mailto=info@nooncapitalandfinance.com"
            className="border border-maincolor py-2 px-5 rounded-md text-2xl sm:text-lg font-bold"
          >
            info@nooncapitalandfinance.com
          </Link>
        </div>
        <div className="w-1/2 sm:w-full py-4 px-32 xl:px-24 lg:px-16 md:px12 sm:px-8 bg-maincolor text-purewhite min-h-[60vh] flex flex-col items-center justify-center why-us">
          <FaPhone className="text-6xl mb-3" />
          <Link
            to="tel=+2348034083476"
            className="border border-purewhite py-2 px-5 rounded-md text-2xl sm:text-lg font-bold"
          >
            +234 (805) 899 9969
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CTA;
