import React from 'react';
import PageHeader from '../components/PageHeader';
import { motion } from 'framer-motion';

function Privacy() {
  return (
    <div className="p-12 md:p-6 sm:p-2">
      <div className="shadow-lg p-12 md:p-6">
        <PageHeader title="Privacy Policy" />
        <div className="flex flex-col px-32 sm:px-4">
          <div className="mt-8 space-y-4">
            <p className="my-4">Last Updated: 1st January, 2024</p>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl md:text-2xl font-bold uppercase underline underline-offset-4 text-maincolor">
                1. Overview
              </h2>
              <p>
                Noon Capital and Finance is committed to protecting the privacy
                and confidentiality of your personal information. This Privacy
                Policy outlines how we collect, use, disclose, and safeguard
                your data when you interact with our website, services, or other
                offerings.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl md:text-2xl font-bold uppercase underline underline-offset-4 text-maincolor">
                2. Information We Collect
              </h2>
              <p>
                We may collect various types of information, including but not
                limited to:
                <ul>
                  <li className="list-disc ml-4">
                    Personal Information: Name, contact details, identification
                    information.
                  </li>
                  <li className="list-disc ml-4">
                    Financial Information: Transaction details, credit history,
                    and other financial data.
                  </li>
                  <li className="list-disc ml-4">
                    Log and Device Information: IP address, device type, browser
                    information.
                  </li>
                </ul>
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl md:text-2xl font-bold uppercase underline underline-offset-4 text-maincolor">
                3. How We Use Your Information
              </h2>
              <p>
                We use the collected information for the following purposes:
                <ul>
                  <li className="list-disc ml-4">
                    Providing and improving our services.
                  </li>
                  <li className="list-disc ml-4">
                    Personalizing user experience.
                  </li>
                  <li className="list-disc ml-4">
                    Processing transactions and maintaining financial records.
                  </li>
                  <li className="list-disc ml-4">
                    Communicating with you about our services and promotions.
                  </li>
                </ul>
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl md:text-2xl font-bold uppercase underline underline-offset-4 text-maincolor">
                4. Information Sharing
              </h2>
              <p>
                We do not sell, trade, or otherwise transfer your personally
                identifiable information to third parties without your consent.
                However, we may share information with trusted third parties,
                such as financial institutions, for specific service-related
                purposes.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl md:text-2xl font-bold uppercase underline underline-offset-4 text-maincolor">
                5. Security Measures
              </h2>
              <p>
                We implement industry-standard security measures to protect your
                personal information from unauthorized access, disclosure,
                alteration, and destruction.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl md:text-2xl font-bold uppercase underline underline-offset-4 text-maincolor">
                6. Cookies and Tracking Technologies
              </h2>
              <p>
                Our website may use cookies and similar technologies to enhance
                user experience, analyze trends, and administer the website. You
                can control the use of cookies through your browser settings.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl md:text-2xl font-bold uppercase underline underline-offset-4 text-maincolor">
                7. Your Choice
              </h2>
              <p>
                You have the right to review, update, or delete your personal
                information. You can also opt out of receiving promotional
                communications from us.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl md:text-2xl font-bold uppercase underline underline-offset-4 text-maincolor">
                8. Changes to the Privacy Policy
              </h2>
              <p>
                We may update this Privacy Policy periodically. Any changes will
                be posted on our website with the updated date.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl md:text-2xl font-bold uppercase underline underline-offset-4 text-maincolor">
                9. Contact Information
              </h2>
              <p>
                If you have any questions or concerns regarding this Privacy
                Policy, please contact us at info@nooncapitalandfinance.com.
              </p>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
