import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

function PracticeArea() {
  const buttonAni = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0, transition: { delay: 3 } },
  };

  return (
    <div className="py-12 px-32 xl:px-24 lg:px-16 md:px12 sm:px-8 bg-gray min-h-screen flex flex-col items-center justify-center why-us">
      <div>
        <h1 className="mb-12 md:mb-12 text-maincolor text-5xl font-bold">
          Our Practice Areas
        </h1>
      </div>
      <div className="grid grid-cols-2 gap-8 font-semibold mb-12">
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.2, duration: 0.5 },
          }}
          whileHover={{ scale: 1.1 }}
          viewport={{ once: true }}
          className="col-span-1 md:col-span-2 flex flex-col items-center text-center gap-5 p-8 transition-all duration-300 ease-in-out bg-gradient-to-b from-maincolor to-black text-whitesmokecolor rounded-md shadow-black shadow-lg"
        >
          <h2 className="text-2xl  font-bold uppercase underline underline-offset-4">
            Corporate Finance
          </h2>
          <p className="sm:text-sm">
            Noon Capital and Finance's Corporate Finance practice area is
            dedicated to assisting businesses in managing their financial
            activities and optimizing capital structure. Services include
            financial planning, fundraising, mergers and acquisitions, and
            strategic financial decision-making. Our experts collaborate with
            clients to enhance financial performance and achieve sustainable
            growth.
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.4, duration: 0.5 },
          }}
          whileHover={{ scale: 1.1 }}
          viewport={{ once: true }}
          className="col-span-1 md:col-span-2 flex flex-col items-center text-center gap-5 p-8 transition-all duration-300 ease-in-out bg-purewhite text-maincolor rounded-md shadow-black shadow-lg"
        >
          <h2 className="text-2xl  font-bold uppercase underline underline-offset-4">
            Project Finance
          </h2>
          <p className="sm:text-sm text-black">
            In the Project Finance practice area, Noon Capital and Finance
            Specializes in structuring, financing, and managing of large-scale
            projects. Whether it's infrastructure development, energy projects,
            or real estate ventures, our team provides tailored financial
            solutions, risk management strategies, and expert guidance
            throughout the project lifecycle.
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.6, duration: 0.5 },
          }}
          whileHover={{ scale: 1.1 }}
          viewport={{ once: true }}
          className="col-span-1 md:col-span-2 flex flex-col items-center text-center gap-5 p-8 transition-all duration-300 ease-in-out bg-gradient-to-b from-maincolor to-black text-whitesmokecolor rounded-md shadow-black shadow-lg"
        >
          <h2 className="text-2xl  font-bold uppercase underline underline-offset-4">
            Trade Finance
          </h2>
          <p className="sm:text-sm">
            Navigating the complexities of international trade is simplified
            with Noon Capital and Finance's Trade Finance practice. We offer a
            range of financial tools that includes Letters of Credit, Trade
            Credit Insurance, and Export Financing, to facilitate smooth and
            secure cross-border transactions. Our solutions mitigate risks and
            optimize cash flow for businesses engaged in global trade.
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.4, duration: 0.5 },
          }}
          whileHover={{ scale: 1.1 }}
          viewport={{ once: true }}
          className="col-span-1 md:col-span-2 flex flex-col items-center text-center gap-5 p-8 transition-all duration-300 ease-in-out bg-purewhite text-maincolor rounded-md shadow-black shadow-lg"
        >
          <h2 className="text-2xl  font-bold uppercase underline underline-offset-4">
            Investment Management
          </h2>
          <p className="sm:text-sm text-black">
            The Investment Management practice area at Noon Capital is designed
            for individuals and businesses seeking to maximize returns on their
            investments. Our seasoned investment professionals analyze market
            trends, create diversified portfolios, and provide strategic advice
            to help clients achieve their financial objectives while managing
            risk effectively.
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.6, duration: 0.5 },
          }}
          whileHover={{ scale: 1.1 }}
          viewport={{ once: true }}
          className="col-span-1 md:col-span-2 flex flex-col items-center text-center gap-5 p-8 transition-all duration-300 ease-in-out bg-gradient-to-b from-maincolor to-black text-whitesmokecolor rounded-md shadow-black shadow-lg"
        >
          <h2 className="text-2xl  font-bold uppercase underline underline-offset-4">
            Financial Consultancy
          </h2>
          <p className="sm:text-sm">
            Noon Capital and Finance's Financial Consultancy practice area
            offers specialized advisory services, guiding clients through
            complex financial challenges. Whether it's strategic planning, risk
            assessment, or financial restructuring, our consultants bring
            in-depth expertise to help clients make informed decisions and
            navigate the intricacies of the financial landscape.
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.4, duration: 0.5 },
          }}
          whileHover={{ scale: 1.1 }}
          viewport={{ once: true }}
          className="col-span-1 md:col-span-2 flex flex-col items-center text-center gap-5 p-8 transition-all duration-300 ease-in-out bg-purewhite text-maincolor rounded-md shadow-black shadow-lg"
        >
          <h2 className="text-2xl  font-bold uppercase underline underline-offset-4">
            Risk Management
          </h2>
          <p className="sm:text-sm text-black ">
            Our risk management practices focus on analysing, identifying and
            mitigating risks that businesses may face. Our experts collaborate
            with Clients to develop risk management strategies, ensuring that
            they are well-prepared to navigate uncertainties and safeguard their
            financial interests.
          </p>
        </motion.div>
      </div>
      <div>
        <Link to={'/about'}>
          <motion.button
            initial={{ opacity: 0, y: 200 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.2, duration: 0.5 },
            }}
            whileHover={{ scale: 1.1 }}
            viewport={{ once: true }}
            className="bg-gradient-to-r from-maincolor to-black text-whitesmokecolor hover:bg-gradient-to-l uppercase font-bold py-4 px-12 md:py-3 md:px-6 rounded-md transition-all duration-300 ease-in-out hover:bg-white hover:text-gray-700"
          >
            Know More
          </motion.button>
        </Link>
      </div>
    </div>
  );
}

export default PracticeArea;
